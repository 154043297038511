.home-template {
  width: 100%;
  display: flex;
  flex-direction: column;

  .headers-container {
    border-bottom: 1px solid $gray;
    padding-bottom: 10px;
  }

  .text {
    &__header {
      margin-bottom: 20px;
      color: $dark-gray;
      line-height: 16.37px;
      letter-spacing: 4.8px;
      font-size: 20px;
      font-weight: 150;
    }
  }
}