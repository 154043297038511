@import './colors.scss';
@import './global/normalize.scss';


// global imports
@import './global/text.scss';
@import './global/utils.scss';
@import './global/buttons.scss';

// View imports
@import './views/order-details.scss';
@import './views/orders.scss';
@import './views/home.scss';
@import './views/reports';

// Component imports
@import './components/filter-menu.scss';
@import './components/order-status-select.scss';
@import './components/confirm-dialog.scss';
@import './components/side-bar.scss';

.App {
  text-align: center;
  display: flex;
}

.main-content{
  padding: 24px;
  flex-grow: 1;
  padding-top: 16px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.headers-container{
  display: flex;
  justify-content: space-between;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}



.button {
  &__export {
    width: 250px;
    background-color: $peach;
    text-decoration: none;
    letter-spacing: 5px;
    color: white !important;
    margin-left: 5px;
    font-size: 13px;
    margin-top: 1px;
    margin-bottom: 0px;
  }
}