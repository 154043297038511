.flex{
    
  &-column{
      &-center{
          display: flex;
          flex-direction: column;   
          justify-content: center;
          align-items: center;
      }

      &-left{
          display: flex;
          flex-direction: column;   
          justify-content: center;
          align-items: flex-start;
      }
  }

  &-row{
      &-center{
          flex-direction: row;
          display: flex;
          justify-content: center;
          align-items: center;
      }
  }
}

.mw {
  &-250 {
    min-width: 250px;
  }

  &-300 {
    min-width: 300px;
  }

  &-420 {
    min-width: 420px;
  }
}

.mb{

  &-25{
      margin-bottom: 25px;
  }

  &-10{
      margin-bottom: 10px;
  }

}

.mt{

  &-25{
      margin-top: 25px;
  }

}

.mr {
  &-10 {
    margin-right: 10px;
  }

  &-15 {
    margin-right: 15px;
  }

  &-20 {
    margin-right: 20px;
  }
}

.pr {
  &-5 {
    padding-right: 5px;
  }

  &-10 {
    padding-right: 10px;
  }

  &-13 {
    padding-right: 13px;
  }

  &-15 {
    padding-right: 15px;
  }

  &-20 {
    padding-right: 20px;
  }

  &-25 {
    padding-right: 25px;
  }

  &-30 {
    padding-right: 30px;
  }

  &-35 {
    padding-right: 35px;
  }

  &-40 {
    padding-right: 40px;
  }

  &-50 {
    padding-right: 50px;
  }
}

.pb {

  &-5{
      padding-bottom: 5px;
  }

  &-10{
      padding-bottom: 10px;
  }

  &-15{
      padding-bottom: 15px;
  }

  &-20 {
    padding-bottom: 20px;
  }

  &-25 {
    padding-bottom: 25px;
  }
}

.pt {
  &-10 {
    padding-top: 10px;
  }

  &-15 {
    padding-top: 15px;
  }

  &-20 {
    padding-top: 20px;
  }

  &-25 {
    padding-top: 25px;
  }

  &-40 {
    padding-top: 40px;
  }

  &-50 {
    padding-top: 50px;
  }
}

