.orders-template {
  width: auto;
  display: flex;
  flex-direction: column;

  .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    margin: 0;
  }

  .MuiTableRow-root:last-child {
    border: none;
    border-bottom: 1px solid $light-gray;
  }
  .MuiTableRow-root{
    text-decoration: auto;

    &:hover{
      background-color: rgba($light-gray, 0.5);
    }
  }
  
  .id-column{
    min-width: 100px;
  }

  .MuiTableCell-footer {
    display: contents;
  } 

  .MuiBox-root {
    .MuiButtonBase-root {
      padding: 0px;
      margin: 0 2px;
    }
  }

  .headers-container {
    border-bottom: 1px solid $gray;
    padding-bottom: 10px;
  }

  .MuiTablePagination-root {
    border-bottom: none;
  }

  .table-container {
    margin-top: 25px;
    width: auto;
  }

  .filter-container {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .text {
    &__header {
      margin-bottom: 20px;
      color: $dark-gray;
      line-height: 16.37px;
      letter-spacing: 4.8px;
      font-size: 20px;
      font-weight: 150;
    }
    &__current-filter {
      padding-left: 8px;
      font-size: 12px;
    }
  }
  .MuiTableHead-root {
    background-color: $dark-gray;
  }

  .MuiTableCell-head {
    color: white;
  }

  .MuiTableCell-body {
    color: $dark-gray;
    cursor: pointer;
  }

  .has_error{
    background-color: $error;
  }

  .MuiTablePagination-spacer {
    flex: 0 0 0%;
  }

  .MuiButton-containedPrimary {
    height: fit-content;
    width: 200px;
  }

  .button {
    &__export {
      width: 250px;
      background-color: $peach;
      text-decoration: none;
      letter-spacing: 5px;
      padding-top: 10px;
      color: white !important;
    }
  }

  .css-tpgerd-MuiTableRow-root:last-child td {
    border: none;
  }
}