

.template-reports{

    .reports-main{
        height: 800px;
        margin-top: 25px;
    }

    .reports-plan-pies .MuiPaper-root{
        height: 400px;
        padding-top: 25px;
    }
    .reports-header{
        display: flex;
        justify-content: space-between;
        padding: 0px 40px;

        .button__export {
            width: auto;
            padding: 8px 20px;
        }
    }


}