.confirm-dialog-template {
    .text {
        &__title {
            text-align: center;
        }

        &__content {
            text-align: left;
            padding: 60px;
            padding-bottom: 10px;
        }

        &__warning {
            text-align: center;
            color: red;
        }
    }

    .button {
        &__confirm {
            width: 100%;    
            background-color: $peach;
            text-decoration: none;
            letter-spacing: 5px;
            padding-top: 10px;
            // border: 1px solid $peach;
            color: white !important;

            &:hover {
                background-color: $white;
                color: $peach !important;
                border: 1px solid $peach;
            }
        }

        &__modal-close{
            position: absolute;
            text-transform: uppercase;
            color: #5e5e5e;
            font-size: 8px;
            font-weight: 100;
            letter-spacing: 2.4px;
            cursor: pointer;
            top: 12px;
            right: 9px;
        }
    }

    .actions{
        padding: 20px 60px;
    } 

    .MuiDialogContentText-root {
        font-family: 'Nunito', sans-serif;
    }
}