.order-details-template {
  width: 100%;
  display: flex;
  flex-direction: column;

  .headers-container {
    border-bottom: 1px solid $gray;
    padding-bottom: 10px;
  }

  .react-select__control {
    width: 350px !important;
    border-radius: 0px !important;
  }

  .MuiChip-root {
    background: $peach;
  }

  .title-container {
    display: flex;
    align-items: center;
  }

  .MuiButtonBase-root {
    border-radius: 0px;
  }

  .MuiChip-root{
    margin-left: 15px;
  }


  .row--green{
    background-color: #DCE9D5
  }

  .main-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px ;
    text-align: left;
    margin-top: 10px;
    padding-left: 20px;
    background-color: #FCFCFC;
  }

  .table-list-container {
    width: 98.5%;
    margin: 20px 0px;
    border: 1px solid $light-gray;
    padding-bottom: 20px;


    .MuiTableCell-sizeSmall {
      padding: 15px 24px 5px 15px;
    }

    .MuiTableCell-head {
      font-family: 'Nunito Sans', sans-serif !important;
      border-bottom: none;
      letter-spacing: 2px;
      color: $dark-gray !important;
      background: white;
    }

    .MuiTableCell-body {
      color: $dark-gray;
      font-family: 'Nunito Sans', sans-serif !important;
      text-transform: uppercase;
      letter-spacing: 2px;
  
      padding-left: 15px;
      border-bottom: 1px solid $light-gray !important;
      font-weight: 400;
      border: none;
    }

    .MuiPaper-elevation1 {
      box-shadow: none;
    }
  }

  .order-container {
    border-bottom: 1px solid $gray;
    width: 98.5%;
    display: flex;
    flex-direction: column;

    &__shipping-information {
      border-bottom: none;
    }
  }

  .order-data-container {
    display: flex;
  }

  .order-values-container {
    padding-left: 15px;
  }

  .customer-values-container {
    padding-left: 88px;
  }

  .text {
    &__header {
      margin-bottom: 20px;
      color: $dark-gray;
      line-height: 16.37px;
      letter-spacing: 4.8px;
      font-size: 20px;
      font-weight: 150;
    }

    &__order-title {
      margin: 20px 0px;
      color: $peach;
      font-size: 18px;
      letter-spacing: 4.8px;
      line-height: 16.37px;
    }

    &__information {
      letter-spacing: 2px;
      font-weight: 400;
    }

    &__input-type {
      letter-spacing: 3.5px;
    }

    &__total {
      padding-top: 10px;
      border-top: 1px solid #CCCCCC;
    }
  }


  .external-link{
    color: $peach !important;
    margin-left: 10px;
  }

  .button__export {
    margin-right: 10px;
    background-color: $peach;
    color: $white !important;
    

      &:hover{
        background-color: rgb(148, 77, 62);
      }
  }


  .button {
    &__submit {
      width: 105px !important;
      margin-left: 0px !important;
    }
  }

  .inputs-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
  }

  .values-container {
    display: flex;
    
    // div {
    //   width: 175px;
    // }

    // #react-select-3-listbox {
    //   background: red;
    // }

    .react-select .react-select__control {
      width: 200px;
    }

    .MuiOutlinedInput-root {
      width: 350px;
      border-radius: 0px;
    }

    .MuiButtonBase-root {
      border-radius: 0px;
      width: 200px;
      height:fit-content;
      margin-left: 45px;
    }

    .MuiOutlinedInput-root {
      border-radius: 0px;
      width: 350px;
      height: 40px;
      background: #FCFCFC;
    }

    .text-field-input {
      height: 40px;
    }

    .logo {
      width: 250px;
      height: auto;
    }
  }
}

.logo-container{
  position: absolute;
  right: 0px;
  width: 200px;
  text-align: center;
  margin-right: 10px;
  img{
    width: 100%;
  }
}

.MuiPaper-root{
  position: relative;
}

.bltextarea .MuiOutlinedInput-root{
  height: auto !important;

}


.inline-input{
  padding: initial;
  width: 300px;
}

.input-group--stacked{
  display: flex;

}
.ml-10{
  margin-left: 10px;
}